import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Powders from 'src/components/OurProducts/Powders'
import SEO from 'src/components/shared/SEO'
import Category from 'src/components/Products/category'
import PowdersBg from '../../assets/img/categories/powders.png'

const SEO_CONFIG = {
  title: 'toppers | Finn',
  description:
    'Shop all the products that Finn has to offer. We formulate pet supplements that combine trusted research and modern wellness. Get trusted nutrition with Finn.',
  keywords: ['Toppers']
}

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Category title="Toppers" image={PowdersBg}>
          <Powders />
        </Category>
      </Layout>
    </>
  )
}

export default ProductsPage
